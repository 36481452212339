<template>
  <div class="body">
    <!-- ----------------------------第三部分 影响力记录-------------------------------------------- -->
    <div v-for="(item, index) in tabList" :key="index" :title=item.title
         title-style="font-size: 4.266vw;font-weight: 600">
      <div @click="choiceDate" class="flex_able"
           style="background: #ffffff;height: var(--80);padding-left: var(--30);padding-right: var(--30);">
        <div class="font-size32 font-color1B1B1B" style="flex: 1;font-weight: bold;">
          全部
        </div>
        <title-arrow :text="selectDate"/>
      </div>
      <div style="height: var(--1);background: #DDDDDD;border-radius: var(--4);"></div>
      <van-list v-model=item.loading :immediate-check="false" :finished=item.finished
                :finished-text=item.finishedText
                @load="getTabListContent(item,index)" v-if="item.contentList.length > 0">
        <div v-for="(item, index) in item.contentList" :key="index"
             style="height: var(--138);">
          <div class="flex_center"
               style="justify-content: center;height: var(--138);">
            <div
                style="margin-left: var(--30);margin-right: var(--30);flex:1;font-size: var(--30);
                  font-weight: 400;color: #1B1B1B;">
              <div class="oneLine" style="line-height: var(--42)">
                {{ item.flowTypeDetail }}
              </div>
              <div
                  style="font-weight: 400;margin-top: var(--6);font-size: var(--24);color: #999999;line-height: var(--34)">
                {{ item.flowTypeExplain }}
              </div>
            </div>
            <div
                style="margin-right: var(--30);display:flex;flex-direction:column;align-items:end;font-size: var(--28);font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1B1B1B;">
              <div v-if="item.rollType == 1" class="oneLine"
                   style="max-width:var(--350);width: 100%;line-height: var(--40);text-align: right;color:#026AFC;">
                +{{ item.amount }}
              </div>
              <div v-if="item.rollType != 1" class="oneLine"
                   style="max-width:var(--350);width: 100%;line-height: var(--40);text-align: right;color: #FF1F1F">
                -{{ item.amount }}
              </div>
              <div class="oneLine"
                   style="max-width:var(--350);width: 100%;text-align: right;font-weight: 400;font-size: var(--24);color: #999999;height: var(--34);margin-top: var(--10);">
                {{ item.ctime.replace("T", " ") }}
              </div>
            </div>
          </div>

        </div>
      </van-list>

      <div
          style="text-align: center;width: 94.8vw;border-radius: 1.3vw;background: #fff;position: relative;line-height: 19.7vw;color: #000;font-size: 4vw;font-weight: bold;margin: 2.8vw auto auto auto;"
          v-if="item.contentList.length === 0 && index === 0">暂无可用明细
      </div>
    </div>

    <!-- 时间选择器 -->
    <date-choice-dialog type="year-month" ref="controlDateDialog" @handleChange="dateCallback"
                        @handleChangeSelectDate="selectDateCallback"/>
  </div>
</template>

<script>

import TitleArrow from "@/components/TitleArrow.vue";
import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import DateChoiceDialog from "@/components/DateChoiceDialog.vue";
import {Constants} from "@/utils/constants";

export default {
  components: {DateChoiceDialog, TitleArrow},
  data() {
    return {
      totalBalance: "",//总影响力
      balanceUsed: "",//可用影响力
      balanceFreeze: "",//冻结影响力
      selectDate: "",//显示选中的时间
      monthNum: "",//给接口的时间
      tabList: [],
      assetType: ""
    }
  },
  created() {
    //开始5
    try {
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "true",
        statusBarBgColor: "#ffffff",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.assetType = this.$route.query.assetType
    document.title = this.$route.query.title
    this.queryPowerAccount()
  },
  methods: {
    queryPowerAccount() {
      let params = {
        assetType: this.assetType,
      }
      let that = this;
      Api.queryPowerAccount(params).then((result) => {
        if (result.code === 100) {
          that.balanceUsed = result.data?.accountVo?.balance
        } else {
          Toast(result.msg);
        }
      })
    },
    getTabList() {
      return [
        {
          title: "可用" + Constants.Z_VALUE,
          assetType: this.assetType,
          finished: false,
          pageNo: 1,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        }
      ]
    },
    //组件回调 经过各种判断后返回加密后文字
    selectDateCallback(value) {
      console.log(value);
      this.selectDate = value
    },
    //时间选择器 处理后给接口的日期格式
    dateCallback(value) {
      console.log(value);
      this.monthNum = value
      this.tabList = this.getTabList()
      this.tabList.forEach((item, index) => {
        this.getTabListContent(item, index)
      })
    },
    choiceDate() {
      //显示日期
      this.$refs.controlDateDialog.controlDialog(true);
    },
    getTabListContent(item, index) {
      console.log(index)
      let tab = item
      if (tab.finished) {
        return;
      }
      let data = {
        pageNo: tab.pageNo,
        pageSize: tab.pageSize,
        assetType: tab.assetType,
        monthNum: this.monthNum
      }
      Api.queryPowerRecord(data).then(res => {
        if (res.code === 100) {
          let pageInfo = res.data.pageInfo
          let resData = pageInfo,
              list = resData.list;
          if (resData.isLastPage) { // 没有更多了
            tab.finished = true;
          }
          tab.contentList = tab.contentList.concat(this.handleList(list));
          tab.pageNo = resData.nextPage;
          tab.loading = false;
        } else {
          Toast(res.msg);
        }
      })
    },
    handleList(list) {
      // list.forEach(item => {
      //
      // })
      return list;
    },
    handleList2(list) {
      // list.forEach(item => {
      //   item['redPackName'] = item.sendUserName
      //   item['time'] = item.getTime.replace("T"," ")
      //   item['alreadyGrabDetail'] = " "
      // })
      return list;
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.topVitality {
  width: var(--320);
  display: flex;
  flex-direction: column
}

.topVitalityTitle {
  font-size: var(--28);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: var(--40);
}

.topVitalityDetail {
  width: var(--304);
  margin-top: var(--8);
  font-size: var(--48);
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: var(--66);
}

::v-deep .textClass {
  color: #999999;
  font-size: var(--25);
  line-height: var(--34);
}

::v-deep .textImg {
  margin-left: var(--8);
  width: var(--24);
  height: var(--14);
  content: url("https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/47c803e7-4ab3-46bd-9397-b317fa2fe4ae.png");
}
</style>
